import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../store/StoreContext";
import { LoadingCircle } from "../UI/LoadingCircle";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
}));

const Content = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    document.title = "ACMon " + t("404_PAGE");
  }, []);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4">{t("PAGE_NOT_FOUND")}</Typography>
    </div>
  );
};

export const NoMatch = observer(() => {
  const {
    loadingLocales,
    userStore: { userId },
  } = React.useContext(StoreContext);
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      if (userId) return history.push("/");

      return history.push("/auth");
    }, 1000);
  }, []);

  if (loadingLocales) return <LoadingCircle />;

  return <Content />;
});
