import React from "react";
import { Box } from "@material-ui/core";

export const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    wrapperStyles = null,
    boxStyles = null,
    ...rest
  } = props;

  return (
    <div style={{ ...wrapperStyles }}>
      {value === index && (
        <Box style={{ ...boxStyles }} {...rest}>
          {" "}
          {children}{" "}
        </Box>
      )}
    </div>
  );
};
