import { makeObservable, observable, computed, action } from "mobx";
import axios from "axios";
import Cookies from "universal-cookie/es6";
import { toast } from "react-toastify";

const toastOptions = {
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
};

class UserStore {
  _userId = null;
  _email = null;
  _token = null;
  _name = null;
  _surname = null;
  _phoneNumber = null;
  modalsStore = null;
  setTokenFunc = null;
  constructor(modalStore, setToken) {
    makeObservable(this, {
      modalsStore: observable,
      _userId: observable,
      _email: observable,
      _token: observable,
      _name: observable,
      _surname: observable,
      _phoneNumber: observable,

      userId: computed,
      phoneNumber: computed,
      name: computed,
      surname: computed,
      token: computed,
      email: computed,

      setUserData: action,
      resetUserData: action,
      tryLoginGoogle: action,
      tryLogin: action,
      tryRegister: action,
    });
    this.modalsStore = modalStore;
    this.setTokenFunc = setToken;
    const cookies = new Cookies(document.cookie);
    const user = cookies.get("user");
    if (user !== undefined) this.setUserData(user);
  }

  get userId() {
    return this._userId;
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  get name() {
    return this._name;
  }

  get surname() {
    return this._surname;
  }

  get token() {
    return this._token;
  }

  get email() {
    return this._email;
  }

  setUserData = (user) => {
    const { id, email, name, phoneNumber, token, surname = null } = user;
    this.setTokenFunc(token);
    this._userId = id;
    this._email = email;
    this._token = token;
    this._phoneNumber = phoneNumber;
    this._name = name;
    this._surname = surname;
    const cookies = new Cookies(document.cookie);
    const expires = new Date(
      new Date(Date.now()).setDate(new Date(Date.now()).getDate() + 14)
    );
    cookies.set("user", user, { expires });
  };

  resetUserData = () => {
    this._userId = null;
    this._email = null;
    this._token = null;
    this._phoneNumber = null;
    this._name = null;
    this._surname = null;
    const cookies = new Cookies(document.cookie);
    cookies.remove("user");
  };

  tryLoginGoogle = async (response, callback) => {
    const { googleId, profileObj, accessToken } = response;
    const id = toast.loading("Logging by Google...");
    await axios
      .post(`${process.env.REACT_APP_AUTH}`, {
        access_token: accessToken,
      })
      .then(({ data }) => {
        toast.update(id, {
          render: "Success logged",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });

        const user = {
          id: googleId,
          token: data.token,
          email: profileObj.email,
          phoneNumber: "+380970000000",
          name: profileObj.givenName,
          surname: profileObj.familyName,
        };
        this.setUserData(user);
        callback();
      })
      .catch((e) => {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        console.log("error logging by google: ", e);
      });
  };

  tryLogin = async (data, callback) => {
    const id = toast.loading("Logging by email...");
    await axios
      .post(`${process.env.REACT_APP_AUTH_LOGIN}`, data)
      .then(({ data }) => {
        toast.update(id, {
          render: "Successfully logged",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });

        const user = {
          id: data.key,
          token: data.key,
          email: data.email,
        };
        this.setUserData(user);
        callback();
      })
      .catch((e) => {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        console.log("error logging by login form: ", e);
        if (e.response) {
          const { data } = e.response;
          if (data) {
            let body = "";
            Object.keys(data).forEach((key) => (body += data[key][0]));
            this.modalsStore.createMessage("Error", body);
          }
        }
      });
  };

  tryRegister = async (data, callback) => {
    //  send post to backend
    const id = toast.loading("Registering...");
    await axios
      .post(`${process.env.REACT_APP_AUTH_REGISTER}`, data)
      .then((response) => {
        toast.update(id, {
          render: "Registering successfull",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
        console.log(response);
        const body = response.data;
        this.modalsStore.createMessage("Success", body, 90, () => callback());
      })
      .catch((e) => {
        toast.update(id, {
          render: "Something went wrong...",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        console.log("error register: ", e);
        if (e.response) {
          const { data } = e.response;
          if (data) {
            let body = "";
            Object.keys(data).forEach((key) => (body += data[key][0]));
            this.modalsStore.createMessage("Error", body);
          }
        }
      });
  };
}

export default UserStore;
