import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import store from "./store/AppStore";

const createLocales = async () => {
  let translationEN = null;
  let translationUK = null;
  // load the locales
  await fetch(process.env.PUBLIC_URL + "/locales/en/translation.json")
    .then((r) => r.json())
    .then((r) => {
      if (r) translationEN = r;
    })
    .catch((e) => console.log("error en load: ", e));
  await fetch(process.env.PUBLIC_URL + "/locales/uk/translation.json")
    .then((r) => r.json())
    .then((r) => {
      if (r) translationUK = r;
    })
    .catch((e) => console.log("error uk load: ", e));
  // the translations
  const resources = {
    en: {
      translation: translationEN,
    },
    uk: {
      translation: translationUK,
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      {
        resources,
        lng: localStorage.getItem("locale") ?? "uk",
        fallbackLng: "en",
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      },
      () => store.setLoadingLocales(false)
    );
};

export default createLocales();
