import React from "react";
import { makeStyles, Tabs, Tab, Typography } from "@material-ui/core";
import { useHistory, Link, Redirect } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { TabPanel } from "../UI/TabPanel";
import { StoreContext } from "../../store/StoreContext";
import { Register } from "../Forms/Register";
import { Login } from "../Forms/Login";
import { LoadingCircle } from "../UI/LoadingCircle";
import { MessageDialog } from "../Modals/MessageDialog";

const clientID = `${process.env.REACT_APP_GOOGLE_CLIEND_ID}.apps.googleusercontent.com`;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    minHeight: 300,
    minWidth: 250,
  },
  input: {
    marginBottom: 5,
  },
  submitButton: {
    marginTop: 25,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "gold",
    },
  },
}));

const TabsComponent = ({ redirect, registerRedirect }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  React.useEffect(() => {
    document.title = "ACMon " + t("AUTH_PAGE");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab className={classes.tab} label={t("LOGIN")} value={0} />
        <Tab className={classes.tab} label={t("REGISTRATION")} value={1} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Login classes={classes} redirect={redirect} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Register classes={classes} redirect={registerRedirect} />
      </TabPanel>
    </>
  );
};

const GoogleLoginButton = ({ responseGoogle }) => {
  const { t } = useTranslation();

  return (
    <GoogleLogin
      clientId={clientID}
      buttonText={t("LOGIN_BY_GOOGLE")}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};

const GoToAppLink = () => {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: 10 }}>
      <Link to="/">
        <Typography>{t("GO_TO_APP")}</Typography>
      </Link>
    </div>
  );
};

export const Auth = observer(() => {
  const classes = useStyles();
  const {
    loadData,
    setLoading,
    loadingLocales,
    userStore: { tryLoginGoogle, userId },
    modalStore: { messages },
  } = React.useContext(StoreContext);

  const history = useHistory();

  const redirect = () => {
    history.push("/");
    loadData().finally(() => {
      setLoading(false);
    });
  };

  const registerRedirect = () => {
    history.push("/");
  };

  const responseGoogle = async (response) => {
    if (response.error || response?.type === "error") return;
    tryLoginGoogle(response, redirect);
  };

  if (userId) return <Redirect to="/" />;

  if (loadingLocales) return <LoadingCircle />;

  return (
    <div className={classes.wrapper}>
      {messages.map((message) => (
        <MessageDialog key={message.id} message={message} />
      ))}
      <TabsComponent redirect={redirect} registerRedirect={registerRedirect} />
      <GoogleLoginButton responseGoogle={responseGoogle} />
      {userId && <GoToAppLink />}
    </div>
  );
});
