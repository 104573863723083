import React from "react";
import { useRouteMatch, Route, useHistory, Redirect } from "react-router-dom";
import axios from "axios";

const TokenComponent = () => {
  const history = useHistory();
  const params = history.location.pathname.slice(14);
  const activateAccount = async () => {
    await axios
      .get(`${process.env.REACT_APP_AUTH_ACTIVATE_EMAIL}${params}`)
      .then((response) => {
        console.log(response);
        return <Redirect to="/" />;
      })
      .catch((e) => {
        console.log("on activate error: ", e);
        return <Redirect to="/" />;
      });
  };
  React.useEffect(() => {
    document.title = "ACMon";
    activateAccount();
  }, []);
  return <></>;
};

const UidbComponent = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route path={`${url}/`}>
        <Redirect to="/" />
      </Route>
      <Route path={`${url}/:token`}>
        <TokenComponent />
      </Route>
    </>
  );
};

export const ConfirmRegistration = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route path={`${url}/`}>
        <Redirect to="/" />
      </Route>
      <Route path={`${url}/:uidb64`}>
        <UidbComponent />
      </Route>
    </>
  );
};
