import { makeObservable, observable, computed, action } from "mobx";
import axios from "axios";
class ModalsStore {
  _carInfo = null;
  _editingDepartmentID = null;
  _editingCarID = null;
  _addCarShowing = false;
  _addDepartmentShowing = false;
  _commandShowing = false;
  _messages = [];
  userStore = null;
  constructor() {
    makeObservable(this, {
      userStore: observable,
      _editingDepartmentID: observable,
      _editingCarID: observable,
      _carInfo: observable,
      _addCarShowing: observable,
      _addDepartmentShowing: observable,
      _commandShowing: observable,
      _messages: observable,

      carInfo: computed,
      editingCarID: computed,
      editingDepartmentID: computed,
      addCarShowing: computed,
      addDepartmentShowing: computed,
      commandShowing: computed,
      messages: computed,

      setCarInfo: action,
      setEditingDepartmentID: action,
      setEditingCarID: action,
      setAddCarShowing: action,
      setAddDepartmentShowing: action,
      setCommandShowing: action,
      createMessage: action,
      closeMessage: action,
      setUserStore: action,
    });
  }

  setUserStore = (store) => {
    this.userStore = store;
  };

  setAddDepartmentShowing = (value) => {
    this._addDepartmentShowing = value;
  };

  setAddCarShowing = (value) => {
    this._addCarShowing = value;
  };

  setCarInfo = async (id) => {
    if (id === null) {
      this._carInfo = null;
      return;
    }
    await axios
      .get(`${process.env.REACT_APP_CARS}${id}/?fields=last_position`, {
        headers: { Authorization: `Token ${this.userStore.token}` },
      })
      .then(({ data }) => {
        console.log(data);
        this._carInfo = data.last_position;
      });
  };

  setEditingDepartmentID = (id) => {
    this._editingDepartmentID = id;
  };

  setEditingCarID = (uuid) => {
    this._editingCarID = uuid;
  };

  setCommandShowing = (value) => {
    this._commandShowing = value;
  };

  closeMessage = (messageID) => {
    this._messages = this._messages.filter((mess) => mess.id !== messageID);
  };

  createMessage = (
    title = "TITLE",
    description = "Some description",
    height = 60,
    callback = () => console.log("Ok pressed")
  ) => {
    const message = {
      id: Date.now() + (Math.random() % 50),
      title,
      description,
      callback,
      height,
    };
    this._messages.push(message);
  };

  get carInfo() {
    return this._carInfo;
  }

  get editingDepartmentID() {
    return this._editingDepartmentID;
  }

  get editingCarID() {
    return this._editingCarID;
  }

  get addCarShowing() {
    return this._addCarShowing;
  }

  get addDepartmentShowing() {
    return this._addDepartmentShowing;
  }

  get commandShowing() {
    return this._commandShowing;
  }

  get messages() {
    return this._messages;
  }
}

export default ModalsStore;
