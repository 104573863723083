import React from "react";
import {
  Paper,
  TextField,
  IconButton,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Explore, RotateLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { getDateTimeString } from "../../utils";
import { StoreContext } from "../../store/StoreContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: 5,
    fontSize: 10,
    maxWidth: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "0px 10px",
  },
  submitButtons: {
    marginTop: 5,
  },
  menuItem: {
    fontSize: 14,
  },
}));

export const IntervalsForm = () => {
  const classes = useStyles();
  const [selectedCar, setSelectedCar] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const { createSearchHistory, cars } = React.useContext(StoreContext);
  const { t } = useTranslation();

  let maxEndTime =
    startDate !== ""
      ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 3))
      : "";
  if (startDate !== "")
    maxEndTime =
      maxEndTime > new Date(Date.now()) ? new Date(Date.now()) : maxEndTime;
  const minEndDate =
    startDate !== "" ? getDateTimeString(new Date(startDate)) : "";
  const maxStartDate = getDateTimeString(new Date(Date.now())) ?? "";
  const maxEndDate = maxEndTime !== "" ? getDateTimeString(maxEndTime) : "";

  const onChange = (event) => {
    if (event.target.name === "start_time") setStartDate(event.target.value);
    else if (event.target.name === "end_time") setEndDate(event.target.value);
  };

  const onChangeCar = (event) => {
    setSelectedCar(event.target.value);
  };

  const reset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedCar("");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const {
      start_time: { value: startTime },
      end_time: { value: endTime },
      car: { value: car_id },
    } = event.target.elements;

    reset();

    createSearchHistory(startTime, endTime, car_id);
  };

  return (
    <Paper style={{ marginTop: 5 }}>
      <form className={classes.container} onSubmit={onSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
            justifyContent: "space-around",
          }}
        >
          <FormControl>
            <InputLabel id="car-input">{t("CAR_NUMBER")} *</InputLabel>
            <Select
              id="car-input"
              name="car"
              value={selectedCar}
              onChange={onChangeCar}
              required
            >
              {cars.map((car) => (
                <MenuItem
                  key={car.id}
                  value={car.id}
                  className={classes.menuItem}
                >
                  {car.number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label={t("START_DATE_TIME")}
            type="datetime-local"
            name="start_time"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onChange}
            value={startDate}
            InputProps={{
              inputProps: {
                max: maxStartDate,
              },
            }}
            required
          />
          <TextField
            label={t("END_DATE_TIME")}
            type="datetime-local"
            name="end_time"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={onChange}
            disabled={startDate === ""}
            InputProps={{
              inputProps: {
                max: maxEndDate,
                min: minEndDate,
              },
            }}
            required
          />
        </div>
        <div className={classes.submitButtons}>
          <IconButton
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
          >
            <Explore />
          </IconButton>
          <IconButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={reset}
          >
            <RotateLeft />
          </IconButton>
        </div>
      </form>
    </Paper>
  );
};
