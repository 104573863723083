import { observer } from "mobx-react";
import React from "react";

import { StoreContext } from "../../store/StoreContext";
import { LineComponent } from "./LineComponent";

export const RenderDailyRoute = observer(() => {
  const { showHistory, getDailyID, getCarTrackingColor } =
    React.useContext(StoreContext);
  const [daily, setDaily] = React.useState(null);

  React.useEffect(() => {
    setDaily(showHistory.find((sh) => sh.id === getDailyID()));
  }, [showHistory]);
  
  if (daily == undefined) return null;

  return daily.records.map((carMovement) => (
    <LineComponent
      key={carMovement.car}
      records={carMovement.records}
      lineColor={getCarTrackingColor(carMovement.car)}
    />
  ));
});
