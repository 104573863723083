import React from "react";
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";

import { StoreContext } from "../../store/StoreContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  successDialog: {
    height: 60,
    maxHeight: 150,
    maxWidth: 300,
    overflowY: "auto",
  },
});
export const MessageDialog = ({ message }) => {
  const classes = useStyles();
  const {
    modalStore: { closeMessage },
  } = React.useContext(StoreContext);
  const { id, title, description, callback, height } = message;
  const onClose = () => {
    closeMessage(id);
    if (callback) callback();
  };
  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle style={{ color: title === "Success" ? "green" : "red" }}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.successDialog} style={{ height }}>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
