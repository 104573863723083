import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { makeStyles, Typography } from "@material-ui/core";
import * as Leaflet from "leaflet";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../store/StoreContext";
const useStyles = makeStyles({
  text: {
    fontSize: 12,
  },
});

// const imgSource =
//   "https://cdn.dribbble.com/users/1787323/screenshots/14677197/media/d699460e529ff1c026dce3931078ebcb.png";

export const MarkerWrapper = ({ car }) => {
  const classes = useStyles();
  const { departments, precision, models } = React.useContext(StoreContext);
  const { t } = useTranslation();

  const popup = React.useRef();
  let position = null;

  if (
    car.hasOwnProperty("last_position") &&
    car.last_position !== null &&
    car.last_position !== undefined
  ) {
    position = [
      car.last_position.latitude / precision,
      car.last_position.longitude / precision,
    ];
  }

  // const markerImageHtmlStyles = `
  // width: 1.5rem;
  // height: 1.5rem;
  // transform: rotate(-45deg);
  // border-radius: 3rem 3rem 0;`;

  const wrapper = `
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  left: -0.8rem;
  top: -0.3rem;
  position: relative;
  transform: rotate(45deg);
  border-radius: 3rem 3rem 0;
  border: 1px solid #000`;

  const markerHtmlStyles = `
    background-color: ${car.color};
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    border-radius: 3rem 3rem 0;
    border: 1px solid #FFFFFF`;
  // const img = `<img style="${markerImageHtmlStyles}" src="${imgSource}" alt="no image"/>`;

  const icon = Leaflet.divIcon({
    className: "",
    iconAnchor: [0, 24],
    tooltipAnchor: [11, -11],
    popupAnchor: [0, -11],
    html: `<span style="${wrapper}"><span style="${markerHtmlStyles}"></span></span>`,
  });

  if (!position) return null;

  return (
    <Marker position={position} opacity={1} icon={icon}>
      <Tooltip>{`[${
        departments.find((dep) => dep.id == car.department)?.name
      }]:${car.number}`}</Tooltip>
      <Popup ref={popup}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" className={classes.text}>
            {t("CAR_NUMBER")}: {car.number}
          </Typography>
          <Typography variant="h6" className={classes.text}>
            {t("MODEL")}: {models.find((m) => m.id == car.model).name}
          </Typography>
          <Typography variant="h6" className={classes.text}>
            {t("DEPARTMENT")}:{" "}
            {departments.find((dep) => dep.id === car.department)?.name}
          </Typography>
          <Typography variant="h6" className={classes.text}>
            {t("SPEED")}: {car.last_position.speed} {t("KM_H")}
          </Typography>
        </div>
      </Popup>
    </Marker>
  );
};
