import React from "react";
import { FormControl, TextField, Button } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../store/StoreContext";

export const Login = ({ classes, redirect }) => {
  const { t } = useTranslation();
  const {
    userStore: { tryLogin },
  } = React.useContext(StoreContext);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmitLogin(values, resetForm);
    },
  });

  const onSubmitLogin = (values, resetForm) => {
    const { email, password } = values;
    const data = { email, password };
    resetForm();
    tryLogin(data, redirect);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <FormControl className={classes.input} fullWidth>
        <TextField
          type="text"
          name="email"
          label={t("EMAIL_ADDRESS")}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="example@gmail.com"
          required
        />
      </FormControl>
      <FormControl className={classes.input} fullWidth>
        <TextField
          label={t("PASSWORD")}
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="********"
          inputProps={{ minLength: 8 }}
          required
        />
      </FormControl>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<Person />}
      >
        {t("LOGIN")}
      </Button>
    </form>
  );
};
