import React from "react";
import { FormControl, TextField, Button } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

import { StoreContext } from "../../store/StoreContext";

export const Register = ({ classes, redirect }) => {
  const { t } = useTranslation();
  const {
    userStore: { tryRegister },
  } = React.useContext(StoreContext);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    name: yup
      .string("Enter your name")
      .trim()
      .min(2, "Enter more then 2 characters")
      .required("Name is required"),
    surname: yup
      .string("Enter your surname")
      .trim()
      .min(2, "Enter more then 2 characters")
      .required("Surname is required"),
    phone: yup
      .string("Enter your phone number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Phone is required"),
    password: yup
      .string("Enter your password")
      .trim()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      name: "",
      surname: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmitRegistration(values, resetForm);
    },
    validateOnChange: true,
  });

  const handleChangePhone = (values) => {
    const event = {
      target: {
        value: values.value,
        name: "phone",
      },
    };
    formik.handleChange(event);
  };

  const onSubmitRegistration = async (values, resetForm) => {
    const { email, phone, password, name, surname } = values;
    const data = {
      email,
      password,
      mobile_number: phone,
      first_name: name,
      last_name: surname,
    };
    console.log(data);
    resetForm();
    tryRegister(data, redirect);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <FormControl className={classes.input} fullWidth>
        <TextField
          type="text"
          name="email"
          label={t("EMAIL_ADDRESS")}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="example@gmail.com"
          required
        />
      </FormControl>
      <FormControl className={classes.input} fullWidth>
        <TextField
          type="text"
          name="surname"
          label={t("SURNAME")}
          value={formik.values.surname}
          onChange={formik.handleChange}
          error={formik.touched.surname && Boolean(formik.errors.surname)}
          helperText={formik.touched.surname && formik.errors.surname}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="Ivanov"
          required
        />
      </FormControl>
      <FormControl className={classes.input} fullWidth>
        <TextField
          type="text"
          name="name"
          label={t("NAME")}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="Andriy"
          required
        />
      </FormControl>
      <FormControl className={classes.input} fullWidth>
        <NumberFormat
          customInput={TextField}
          format="+38 (###) ###-##-##"
          allowEmptyFormatting
          mask="_"
          name="phone"
          label={t("PHONE_NUMBER")}
          value={formik.values.phone}
          onValueChange={handleChangePhone}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          required
        />
      </FormControl>
      <FormControl className={classes.input} fullWidth>
        <TextField
          type="password"
          name="password"
          label={t("PASSWORD")}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          FormHelperTextProps={{ style: { maxWidth: 250 } }}
          placeholder="********"
          inputProps={{ minLength: 8 }}
          required
        />
      </FormControl>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<PersonAdd />}
      >
        {t("REGISTER")}
      </Button>
    </form>
  );
};
