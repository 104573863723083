import React from "react";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../store/StoreContext";
import { useTranslation } from "react-i18next";
import { LocalePicker } from "./LocalePicker";

const useStyles = makeStyles((theme) => ({
  itemList: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

export const Footer = ({ callback }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    resetState,
    userStore: { resetUserData },
  } = React.useContext(StoreContext);

  const history = useHistory();

  const Logout = () => {
    callback();
    resetUserData();
    resetState();
    history.push("/");
  };

  const showTrips = () => {
    callback();
    history.push("/trips");
  };

  const goToEvents = () => {
    callback();
    history.push("/events");
  };

  return (
    <>
      <List>
        <ListItem className={classes.itemList}>
          <LocalePicker />
        </ListItem>
        <ListItem button onClick={showTrips} className={classes.itemList}>
          <ListItemText primary={t("ALL_TRIPS")} />
        </ListItem>
        <ListItem button onClick={goToEvents} className={classes.itemList}>
          <ListItemText primary={t("EVENTS")} />
        </ListItem>
        <ListItem button onClick={Logout} className={classes.itemList}>
          <ListItemText primary={t("LOGOUT")} />
        </ListItem>
      </List>
    </>
  );
};
