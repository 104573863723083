import React from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  select: {
    border: "none",
    "&:before": {
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
    "&:after": {
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
    "&:hover": {
      border: "none",
      "&:before": {
        border: "none",
        "&:hover": {
          border: "none",
        },
        "&:after": {
          border: "none",
          "&:hover": {
            border: "none",
          },
        },
      },
    },
  },
});

export const LocalePicker = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = React.useState(i18n.language);
  const locales = [
    { label: t("ENGLISH"), name: "en" },
    { label: t("UKRAINIAN"), name: "uk" },
  ];

  const handleChange = (e, data) => {
    const {
      props: { value: newLocale },
    } = data;
    setLocale(newLocale);
    i18n.changeLanguage(newLocale);
    localStorage.setItem("locale", newLocale);
  };
  return (
    <>
      <Select value={locale} onChange={handleChange} className={classes.select}>
        {locales.map((locale, i) => (
          <MenuItem key={i} value={locale.name}>
            {locale.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
