import React from "react";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { LoadingCircle } from "../UI/LoadingCircle";

export const PageWrap = observer(({ children }) => {
  const { loadingLocales, loading } = React.useContext(StoreContext);
  
  if (loadingLocales || loading) return <LoadingCircle />;

  return <>{children}</>;
});
