import React from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { MapContainer, TileLayer, Circle, Polyline } from "react-leaflet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { BreadcrumbsCreator } from "../Creators/BreadcumbsCreator";
import { convertTimeInTrip } from "../../utils";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30,
  },
  wrapper: {
    marginTop: 20,
  },
  link: {
    textDecoration: "none",
    color: "#aaa",
    transition: "0.2s",
    "&:hover": {
      color: "#000",
    },
  },
  map: {
    width: "500px !important",
    height: "400px !important",
  },
  info: {
    marginTop: 30,
  },
});

export const TripInfo = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { trips, precision, loadTripRecords } = React.useContext(StoreContext);
  const [trip, setTrip] = React.useState(trips.find((t) => t.id == uuid));
  const positions =
    trip?.records?.map((rec) => [
      rec.latitude / precision,
      rec.longitude / precision,
    ]) ?? null;

  React.useEffect(() => {
    loadTripRecords(uuid);
    document.title = "ACMon " + t("TRIP_INFO_PAGE");
  }, []);

  React.useEffect(() => {
    setTrip(trips.find((t) => t.id == uuid));
  }, [trips]);

  const breadcrumbsCurrent = {
    color: "textPrimary",
    text: trip?.name ?? "name",
  };
  const breadcrumbsLinks = [
    { color: "inherit", path: "/", class: classes.link, title: t("MAP") },
    {
      color: "inherit",
      path: "/trips",
      class: classes.link,
      title: t("TRIP_LIST"),
    },
  ];

  return (
    <div className={classes.container}>
      <BreadcrumbsCreator
        separator={<NavigateNext fontSize="small" />}
        links={breadcrumbsLinks}
        current={breadcrumbsCurrent}
      />

      <div className={classes.wrapper}>
        <div className="trip_map">
          {positions ? (
            <MapContainer
              className={{ ...classes.map }}
              center={positions[0]}
              minZoom={5}
              zoom={16}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Polyline
                positions={trip?.records?.map((rec) => [
                  rec.latitude / precision,
                  rec.longitude / precision,
                ])}
                pathOptions={{ color: "#ff0000" }}
              />
              {trip?.records?.map((rec) => {
                if (rec.is_parked)
                  return (
                    <Circle
                      center={[
                        rec.latitude / precision,
                        rec.longitude / precision,
                      ]}
                      radius={5}
                      pathOptions={{ color: "#000" }}
                      key={rec.id}
                    />
                  );
              })}
            </MapContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 150,
                margin: 0,
                marginBottom: 15,
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        <div className={classes.info}>
          <Typography align="center">{trip?.name}</Typography>
          <Typography>
            {t("MAX_SPEED")}: {trip?.max_speed} {t("KM_H")}
          </Typography>
          <Typography>
            {t("AVERAGE_SPEED")}: {trip?.avg_speed} {t("KM_H")}
          </Typography>
          <Typography>
            {t("DISTANCE")}: {trip?.distance} {t("KM")}
          </Typography>
          <Typography>
            {t("TRIP_TIME")}:{" "}
            {convertTimeInTrip(trip?.trip_time, t("HOURS"), t("MINUTES"))}
          </Typography>
        </div>
      </div>
    </div>
  );
});
