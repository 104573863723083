import React from "react";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { LineComponent } from "./LineComponent";

export const HistoryRender = observer(() => {
  const { showHistory, getCarTrackingColor } = React.useContext(StoreContext);

  if (showHistory.length === 0) return null;

  return (
    <>
      {showHistory?.map((history) =>
        history.records.map((carMovement) => (
          <LineComponent
            key={carMovement.car}
            records={carMovement.records}
            lineColor={getCarTrackingColor(carMovement.car)}
          />
        ))
      )}
    </>
  );
});
