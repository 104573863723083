import axios from "axios";

export class Http {
  authToken = null;

  constructor(userToken = null) {
    this.authToken = userToken;
  }

  setToken = (token) => {
    this.authToken = token;
  };

  clear = () => {
    this.authToken = null;
  };

  makeAuthHeaderObject = () => {
    return { Authorization: `Token ${this.authToken}` };
  };

  onError = (error) => {
    console.log(error);
    if (error.response) {
      const { status } = error.response;
      if (status == 401) {
        this.clear();
      }
    }
  };

  get = (url, headers = null, config = null) => {
    if (this.authToken === null) return;

    return new Promise(async (resolve, reject) => {
      await axios
        .get(url, {
          headers: { ...headers, ...this.makeAuthHeaderObject() },
          ...config,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          this.onError(e);
          reject({ error: e, status: e?.response?.status });
        });
    });
  };

  post = (url, body = null, headers = null, config = null) => {
    if (this.authToken === null) return;

    return new Promise(async (resolve, reject) => {
      await axios
        .post(url, body, {
          headers: { ...headers, ...this.makeAuthHeaderObject() },
          ...config,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          this.onError(e);
          reject({ error: e, status: e?.response?.status });
        });
    });
  };

  put = (url, body = null, headers = null, config = null) => {
    if (this.authToken === null) return;

    return new Promise(async (resolve, reject) => {
      await axios
        .put(url, body, {
          headers: { ...headers, ...this.makeAuthHeaderObject() },
          ...config,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          this.onError(e);
          reject({ error: e, status: e?.response?.status });
        });
    });
  };
}
