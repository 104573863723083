import React from "react";
import {
  useMapEvent,
  MapContainer,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { HistoryRender, MarkerWrapper } from "../MapComponents";
import { TripsRender } from "../MapComponents/TripsRender";
import { RenderDailyRoute } from "../MapComponents/RenderDailyRoute";

const SetUpAnimatedPane = () => {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: true,
    });
  });
  return <></>;
};

export const MapWrapper = observer(({ tab }) => {
  const { cars, precision, showCars, updateCarsPos } =
    React.useContext(StoreContext);
  const [pos, setPos] = React.useState(
    [
      cars[0]?.last_position?.latitude / precision,
      cars[0]?.last_position?.longitude / precision,
    ] ?? [0, 0]
  );

  React.useEffect(() => {
    const update = setInterval(() => updateCarsPos(), 5000);
    return () => clearInterval(update);
  }, [updateCarsPos]);

  return (
    <>
      <MapContainer center={pos} minZoom={2} zoom={10} zoomControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <ZoomControl position="topright" />
        <SetUpAnimatedPane />

        {tab == "cars" &&
          showCars?.map((car) => <MarkerWrapper key={car.id} car={car} />)}
        {tab == "trips" && <TripsRender />}
        {tab == "history" && <HistoryRender />}
        {tab == "cars" && <RenderDailyRoute />}
      </MapContainer>
    </>
  );
});
