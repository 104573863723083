import React from "react";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const TableCreator = ({
  items,
  onRowClick = () => {},
  parametrs = null,
  excludes = null,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentItems, setCurrentItems] = React.useState(
    items.slice(0, rowsPerPage)
  );
  const [page, setPage] = React.useState(0);
  const { t } = useTranslation();

  React.useEffect(() => {
    setCurrentItems(items.slice(0, rowsPerPage));
  }, [items]);

  const onChangeRowsPerPage = (event) => {
    const rowsPerPageNew = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPageNew);
    setPage(0);
    setCurrentItems(items.slice(0, rowsPerPageNew));
  };

  const onChangePage = (event, newValue) => {
    setPage(newValue);
    const prevPage = newValue < 0 ? 0 : newValue;
    const toItems =
      rowsPerPage * (newValue + 1) > items.length
        ? items.length
        : rowsPerPage * (newValue + 1);
    setCurrentItems(items.slice(rowsPerPage * prevPage, toItems));
  };

  if (items.length === 0) return <div>{t("NO_DATA")}</div>;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {parametrs
              ? parametrs.map((param, i) => (
                  <TableCell align="center" key={i.toString()}>
                    {Object.keys(items[0]).includes(param) ? t(param) : null}
                  </TableCell>
                ))
              : Object.keys(items[0]).map((key, i) => (
                  <TableCell align="center" key={i.toString()}>
                    {t(key)}
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentItems.map((item) => (
            <TableRow
              key={item.id.toString()}
              onClick={() => onRowClick(item.id)}
              hover
            >
              {parametrs
                ? parametrs.map((param, i) => {
                    if (Object.keys(item)?.includes(param)) {
                      let content = item[param];
                      const exclude = excludes.find((ex) => ex.key == param);
                      if (exclude != undefined) {
                        content = exclude.getContent(item[param]);
                      }
                      return (
                        <TableCell align="center" key={i.toString()}>
                          {content}
                        </TableCell>
                      );
                    }
                  })
                : Object.keys(item).map((key, i) => (
                    <TableCell align="center" key={i.toString()}>
                      {item[key]}
                    </TableCell>
                  ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              onChangeRowsPerPage={onChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              onChangePage={onChangePage}
              count={items.length}
              rowsPerPageOptions={[25, 50, 100]}
              page={page}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
