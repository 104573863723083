import React from "react";
import { useTranslation } from "react-i18next";
import { Polyline, CircleMarker, Tooltip } from "react-leaflet";
import * as L from "leaflet";

import { StoreContext } from "../../store/StoreContext";
import { getDateTimeString } from "../../utils";

export const LineComponent = ({ records, lineColor = "#ff0000" }) => {
  const { getCarNumberByID, precision } = React.useContext(StoreContext);
  const { t } = useTranslation();

  if (records === undefined) return null;

  const allRecords = records
    .filter((r) => !r.is_parked)
    .map((rec) => [rec.latitude / precision, rec.longitude / precision, rec]);

  const makeTooltipText = (carNumber, speed, dateTime) => {
    return `<div><strong>${t(
      "CAR_NUMBER"
    )}: </strong> ${carNumber}<br><strong>${t("SPEED")}: </strong> ${speed} ${t(
      "KM_H"
    )}<br><strong>${t("DATE_TIME")}: </strong> ${dateTime}</div>`;
  };
  const eventHandlers = {
    mouseover(e) {
      const tooltip = e.target.getTooltip();
      const point = findClosestPoint(e.latlng, allRecords);
      if (point === null) return;
      const obj = point[2];

      const dateTimeString = getDateTimeString(
        new Date(obj.timestamp),
        true
      ).replace("T", " ");

      const carNumber = getCarNumberByID(obj.car);
      const text = makeTooltipText(carNumber, obj.speed, dateTimeString);

      const container = tooltip._contentNode;
      container.innerHTML = text;
      tooltip.setLatLng(point);
    },
  };

  const findClosestPoint = (latlng, allRecords) => {
    let closestPoint = null;
    let distance = 0;
    allRecords.forEach((point) => {
      if (point.length === 0) return closestPoint;
      if (
        closestPoint == null ||
        distance > L.latLng([point[0], point[1]])?.distanceTo(latlng)
      ) {
        distance = L.latLng([point[0], point[1]])?.distanceTo(latlng);
        closestPoint = point;
      }
    });
    return closestPoint;
  };

  const circleEventHandlers = (data) => ({
    mouseover(e) {
      const tooltip = e.target.getTooltip();
      const dateTimeString = getDateTimeString(
        new Date(data.timestamp),
        true
      ).replace("T", " ");

      const carNumber = getCarNumberByID(data.car);
      const text = makeTooltipText(carNumber, data.speed, dateTimeString);

      const container = tooltip._contentNode;
      container.innerHTML = text;
      tooltip.setLatLng(e.latlng);
    },
  });

  if (records.length === 0) return null;

  return (
    <>
      <Polyline
        positions={allRecords}
        pathOptions={{ color: lineColor ?? "#ff0000" }}
        eventHandlers={eventHandlers}
      >
        <Tooltip></Tooltip>
      </Polyline>

      {records.map((rec, i) => {
        if (rec.is_parked) {
          return (
            <CircleMarker
              key={rec.id.toString() + i}
              center={[rec.latitude / precision, rec.longitude / precision]}
              radius={5}
              pathOptions={{ color: "black" }}
              eventHandlers={circleEventHandlers(rec)}
            >
              <Tooltip></Tooltip>
            </CircleMarker>
          );
        }
        return null;
      })}
    </>
  );
};
