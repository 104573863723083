import React from "react";
import { CircularProgress } from "@material-ui/core";

export const LoadingCircle = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </div>
);
