import React from "react";
import {
  Paper,
  makeStyles,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { NavigateNext, RotateLeft, Search } from "@material-ui/icons";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { BreadcrumbsCreator } from "../Creators/BreadcumbsCreator";
import { IntervalsPickerForm } from "../Forms/IntervalsPickerForm";
import { TableCreator } from "../Creators/TableCreator";
import { convertTimeInTrip, getDateTimeString } from "../../utils";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30,
  },
  formWrapper: {
    marginTop: 20,
    marginBottom: 0,
  },
  link: {
    textDecoration: "none",
    color: "#aaa",
    transition: "0.2s",
    "&:hover": {
      color: "#000",
    },
  },
  carFilterWrapper: {
    marginBottom: 10,
  },
  carFilterForm: {
    width: 220,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const TripsList = observer(() => {
  const classes = useStyles();
  const { trips, getCarTuple } = React.useContext(StoreContext);
  const { t } = useTranslation();

  const [showingTrips, setShowingTrips] = React.useState(trips);
  const [selectedCar, setSelectedCar] = React.useState("");

  const carsList = getCarTuple();

  const { url } = useRouteMatch();
  const history = useHistory();

  React.useEffect(() => {
    document.title = "ACMon " + t("TRIPS_PAGE");
  }, []);

  React.useEffect(() => {
    setShowingTrips(trips);
  }, [trips]);

  const tableKeys = [
    "name",
    "distance",
    "start_time",
    "finish_time",
    "car",
    "trip_time",
    "avg_speed",
    "max_speed",
  ];

  const fixTimeStringTable = (value) => {
    return getDateTimeString(new Date(value)).replace("T", " ");
  };

  const fixKilometersStringTable = (value) => {
    return `${value} ${t("KM_H")}`;
  };

  const tableExcludes = [
    {
      key: "car",
      getContent: (value) => {
        return getCarTuple().find((car) => car.id == value).name;
      },
    },
    {
      key: "start_time",
      getContent: fixTimeStringTable,
    },
    {
      key: "finish_time",
      getContent: fixTimeStringTable,
    },
    {
      key: "distance",
      getContent: (value) => `${value} ${t("KM")}`,
    },
    {
      key: "max_speed",
      getContent: fixKilometersStringTable,
    },
    {
      key: "avg_speed",
      getContent: fixKilometersStringTable,
    },
    {
      key: "trip_time",
      getContent: (value) => convertTimeInTrip(value, t("HOURS"), t("MINUTES")),
    },
  ];

  const breadcrumbsLinks = [
    {
      color: "inherit",
      path: "/",
      class: classes.link,
      title: t("MAP"),
    },
  ];

  const breadcrumbsCurrent = {
    color: "textPrimary",
    text: t("TRIP_LIST"),
  };

  const onFilterSubmit = (event) => {
    event.preventDefault();
    const {
      start_time: { value: startDate },
      end_time: { value: endDate },
    } = event.target.elements;

    if (startDate === "" || endDate === "") setShowingTrips(trips);
    else if (startDate !== "" || endDate !== "")
      setShowingTrips(
        trips.filter(
          (trip) => trip.start_time >= startDate && trip.finish_time <= endDate
        )
      );

    event.target.reset();
  };

  const onFilterTripsByCar = (event) => {
    const carID = event.target.value;
    setSelectedCar(carID);
  };

  const onFilterCarReset = () => {
    setSelectedCar("");
    setShowingTrips(trips);
  };

  const onFilterByCarSubmit = (event) => {
    event.preventDefault();

    if (!selectedCar) return;

    setShowingTrips(trips.filter((trip) => trip.car === selectedCar));
  };

  const handleReset = () => {
    setShowingTrips(trips);
  };

  const onTripPress = (tripID) => {
    history.push(`${url}/${tripID}`);
  };

  return (
    <div className={classes.container}>
      <BreadcrumbsCreator
        separator={<NavigateNext fontSize="small" />}
        links={breadcrumbsLinks}
        current={breadcrumbsCurrent}
      />
      <div className={classes.formWrapper}>
        <Paper style={{ marginTop: 5 }}>
          <IntervalsPickerForm
            onSubmit={onFilterSubmit}
            align={"row"}
            submitIcon={<Search htmlColor="blue" />}
            onResetFilter={handleReset}
          />
        </Paper>
      </div>
      <div className={classes.carFilterWrapper}>
        <form className={classes.carFilterForm} onSubmit={onFilterByCarSubmit}>
          <FormControl fullWidth>
            <InputLabel id="car-input" shrink={!!selectedCar}>
              {t("CAR_NUMBER")} *
            </InputLabel>
            <Select
              id="car-input"
              renderValue={() =>
                carsList.find((car) => car.id === selectedCar).name ?? ""
              }
              onChange={onFilterTripsByCar}
              defaultValue={""}
              value={selectedCar}
              required
            >
              {carsList.map((car) => (
                <MenuItem key={car.id.toString()} value={car.id}>
                  {car.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex" }}>
            <IconButton type="submit">
              <Search htmlColor="blue" />
            </IconButton>
            <IconButton onClick={onFilterCarReset}>
              <RotateLeft htmlColor="red" />
            </IconButton>
          </div>
        </form>
      </div>
      <TableCreator
        items={showingTrips}
        onRowClick={onTripPress}
        parametrs={tableKeys}
        excludes={tableExcludes}
      />
    </div>
  );
});
