import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { Link } from "react-router-dom";

export const BreadcrumbsCreator = ({
  separator = <NavigateNext fontSize="small" />,
  links = [{ class: "", title: "", color: "", path: "/" }],
  current = { color: "textPrimary", text: "" },
}) => {
  return (
    <Breadcrumbs separator={separator}>
      {links.map((link, i) => (
        <Link
          key={i.toString()}
          to={link.path}
          color={link.color}
          className={link.class}
        >
          {link.title}
        </Link>
      ))}
      <Typography color={current.color}>{current.text}</Typography>
    </Breadcrumbs>
  );
};
