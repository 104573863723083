import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";

import { AuthenticatedRoute } from "./components/Routing/AuthenticatedRoute";
import { Auth } from "./components/Pages/Auth";
import { Main } from "./components/Pages/Main";
import { TripsList } from "./components/Pages/TripsList";
import { TripInfo } from "./components/Pages/TripInfo";
import { ConfirmRegistration } from "./components/Pages/ConfirmRegistration";
import { NoMatch } from "./components/Pages/NoMatch";
import { Events } from "./components/Pages/Events";
import { PageWrap } from "./components/Pages/PageWrap";

export const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/auth" exact>
            <Auth />
          </Route>
          <Route path="/registration">
            <ConfirmRegistration />
          </Route>

          <AuthenticatedRoute path="/">
            <Switch>
              <Route path="/" exact>
                <PageWrap>
                  <Main />
                </PageWrap>
              </Route>
              <Route path="/trips">
                <Route path="/trips/" exact>
                  <PageWrap>
                    <TripsList />
                  </PageWrap>
                </Route>
                <Switch>
                  <Route path="/trips/:uuid">
                    <PageWrap>
                      <TripInfo />
                    </PageWrap>
                  </Route>
                </Switch>
              </Route>
              <Route path="/events">
                <PageWrap>
                  <Events />
                </PageWrap>
              </Route>
              <Route path="/not-found">
                <PageWrap>
                  <NoMatch />
                </PageWrap>
              </Route>
              <Route path="*">
                <PageWrap>
                  <NoMatch />
                </PageWrap>
              </Route>
            </Switch>
          </AuthenticatedRoute>
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
    </>
  );
};
