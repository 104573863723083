import React from "react";
import { makeStyles, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../store/StoreContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    top: "22%",
    left: "35%",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: 350,
      left: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      left: "10%",
    },
  },
  closeWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  closeButton: {
    width: 30,
    height: 30,
    padding: "10px 10px 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      color: "#aaa",
    },
  },
  container: {
    minHeight: 250,
    padding: "0px 10px 10px",
  },
  dataWrapper: {
    overflowY: "auto",
    height: 320,
  },
  modalTitle: {
    marginBottom: 15,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
}));

export const CarInfoModal = observer(({ open, handleClose }) => {
  const classes = useStyles();
  const {
    cars,
    departments,
    modalStore: { carInfo },
  } = React.useContext(StoreContext);
  const { t } = useTranslation();
  if (carInfo == null) return null;

  const currCar = cars.find((car) => car.id === carInfo.car);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modal}>
          <div className={classes.closeWrapper}>
            <div className={classes.closeButton} onClick={handleClose}>
              <Close />
            </div>
          </div>
          <div className={classes.container}>
            <Typography variant="h4" className={classes.modalTitle}>
              {t("CAR_INFO")}
            </Typography>
            <Typography
              variant="h4"
              style={{ fontSize: 16, textAlign: "center" }}
            >
              {t("CAR_NUMBER")}: {currCar.number}
            </Typography>
            <Typography
              variant="h4"
              style={{ marginBottom: 15, fontSize: 16, textAlign: "center" }}
            >
              {t("DEPARTMENT")}:{" "}
              {departments.find((dep) => dep.id === currCar.department).name}
            </Typography>
            <div className={classes.dataWrapper}>
              {carInfo.io_elements?.map((io, i) => (
                <Typography
                  key={i}
                  variant="h4"
                  style={{ marginBottom: 5, fontSize: 14 }}
                >
                  [{io.code}] {io.name}: {io.value} {io?.units}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});
