//  returns string based on Date param
export const getDateTimeString = (dateTime, withSeconds = false) => {
  return `${dateTime.toISOString().slice(0, 10)}T${dateTime
    .toLocaleTimeString("uk-UA", {
      timeZone: "Europe/Kiev",
    })
    .slice(0, withSeconds ? 8 : 5)}`;
};

export const convertTimeInTrip = (value, hoursText, minutesText) => {
  const dateTime = value.split(":");
  return dateTime.reduce(
    (acc, curr, i) =>
      curr === "0" ? "" : `${acc} ${curr} ${i === 0 ? hoursText : minutesText}`,
    ""
  );
};
