import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";

import ModalsStore from "./ModalsStore";
import UserStore from "./UserStore";
import { getDateTimeString } from "../utils";
import { Http } from "../Http";

const toastOptions = {
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
};

class AppStore {
  precision = 10000000; // for longitude and latitude
  _cars = [];
  _trips = [];
  _departments = [];
  _models = [];
  _company = [];
  _showTrips = [];
  _showCars = [];
  _lastShowCars = [];
  _showHistory = [];
  _searchHistory = [];

  _currentTrips = [];
  _currentCars = [];

  _loading = true;
  _loadingLocales = true;

  _24hrsIntervalID = null;

  userStore = null;
  modalStore = null;

  http = null;

  constructor() {
    makeObservable(this, {
      userStore: observable,
      _cars: observable,
      _departments: observable,
      _trips: observable,
      _company: observable,
      _models: observable,
      _showTrips: observable,
      _showCars: observable,
      _lastShowCars: observable,
      _showHistory: observable,
      _searchHistory: observable,
      _currentTrips: observable,
      _currentCars: observable,
      _loading: observable,
      _loadingLocales: observable,

      cars: computed,
      departments: computed,
      trips: computed,
      models: computed,
      company: computed,
      showTrips: computed,
      showCars: computed,
      showHistory: computed,
      searchHistory: computed,
      currentCars: computed,
      currentTrips: computed,
      loading: computed,
      loadingLocales: computed,

      updateCars: action,
      addToShowTrips: action,
      removeFromShowTrips: action,
      addToShowCars: action,
      removeFromShowCars: action,
      addToShowHistory: action,
      removeFromShowHistory: action,
      addToSearchHistory: action,
      changeShowDepartment: action,
      updateDepartment: action,
      addDepartment: action,
      updateCar: action,
      addCar: action,
      setCurrentTrips: action,
      setCurrentCars: action,
      resetState: action,
      loadData: action,
      loadCars: action,
      loadModels: action,
      loadDepartments: action,
      loadCompanies: action,
      loadTrips: action,
      setLoading: action,
      setLoadingLocales: action,
      createSearchHistory: action,
      updateCarsPos: action,
      setShowCars: action,
      loadTripRecords: action,
      createDailyMovement: action,
      completeDailyRouteOnRedirect: action,
    });
    this.http = new Http();
    this.modalStore = new ModalsStore();
    this.userStore = new UserStore(this.modalStore, (token) =>
      this.http.setToken(token)
    );
    this.modalStore.setUserStore(this.userStore);
    if (this.userStore.token !== null) {
      this.loadData().finally(() => runInAction(() => (this._loading = false)));
    }
  }

  saveToCache = (dataToCache) => {
    let cached_data = JSON.parse(localStorage.getItem("cached_movement"));
    if (cached_data === null) {
      localStorage.setItem(
        "cached_movement",
        JSON.stringify(dataToCache.map((data) => data.last_position))
      );
    } else {
      cached_data = [
        ...cached_data,
        ...dataToCache.map((data) => data.last_position),
      ];
      localStorage.setItem("cached_movement", JSON.stringify(cached_data));
    }
  };

  updateCars = (newData) => {
    if (this._24hrsIntervalID !== null) {
      const daily = this._searchHistory.find(
        (history) => history.id === this._24hrsIntervalID
      );
      //  continue daily route
      if (daily != undefined) {
        this.continueDailyRoute(daily, newData);
        //  change end time of daily route
        const currentDateTimeString = getDateTimeString(new Date(Date.now()));
        if (currentDateTimeString !== daily.endTime)
          daily.endTime = currentDateTimeString;
      }
    } else {
      this.saveToCache(newData);
    }
    this._cars = this._cars.map((car) => {
      const updatedLastPosition = newData.find(
        (newD) => newD.id == car.id
      ).last_position;
      const newCar = { ...car, last_position: updatedLastPosition };
      return newCar;
    });
  };

  addToShowTrips = async (tripID) => {
    if (!this._showTrips.includes(tripID)) {
      const trip = this._trips.find((t) => t.id == tripID);
      this._showTrips.unshift(tripID);

      if (trip === undefined) return;

      if (trip?.records) return;

      this.loadTripRecords(tripID);
    }
  };

  removeFromShowTrips = (tripID) => {
    this._showTrips = this._showTrips.filter((id) => id !== tripID);
  };

  addToShowCars = async (carID) => {
    await runInAction(async () => {
      if (!this._showCars.includes(carID)) {
        await this.http
          .get(`${process.env.REACT_APP_CARS}${carID}/`)
          .then(({ data }) => {
            const index = this._cars.findIndex((car) => car.id == data.id);
            if (index != -1) this._cars[index] = data;
            this._showCars = [carID, ...this._showCars];
          })
          .catch((e) => this.onError(e));
      }
    });
  };

  removeFromShowCars = (carID) => {
    this._showCars = this._showCars.filter((id) => id !== carID);
  };

  addToShowHistory = (itemID) => {
    if (!this._showHistory.includes(itemID)) this._showHistory.unshift(itemID);
  };

  removeFromShowHistory = (itemID) => {
    this._showHistory = this._showHistory.filter((id) => id !== itemID);
  };

  addToSearchHistory = (item) => {
    if (!this._searchHistory.includes(item)) this._searchHistory.unshift(item);
  };

  changeShowDepartment = (id, value) => {
    const index = this._departments.findIndex((dep) => dep.id === id);
    if (index !== -1) this._departments[index].show = value;
  };

  updateDepartment = async (id, department) => {
    const toastID = toast.loading("Updating department...");
    await this.http
      .put(`${process.env.REACT_APP_DEPARTMENTS}${id}/`, department)
      .then(({ data }) => {
        let updatedItem = this._departments.find((dep) => dep.id === id);
        const toUpdateCars = this._cars.filter(
          (car) => car.department === updatedItem.name
        );
        updatedItem = data;
        toast.update(toastID, {
          render: "Department updated",
          type: "success",
        });
        toUpdateCars.forEach((car) => {
          car.department = updatedItem.name;
        });
        toast.update(toastID, {
          render: "Cars deparment updated",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      })
      .catch((e) => {
        console.log("edit department error: ", e);

        toast.update(toastID, {
          render: "Department update failed",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });

        this.onError(e);

        if (e.response) {
          const data = e.response.data;
          const body = Object.keys(data).reduce(
            (acc, curr) => (acc += `${data[curr]}\n`),
            ""
          );
          this.modalStore.createMessage("Error", body, 50, () =>
            this.loadDepartments()
          );
        }
      });
  };

  addDepartment = async (department) => {
    const id = toast.loading("Please wait...");
    await this.http
      .post(`${process.env.REACT_APP_DEPARTMENTS}`, department)
      .then(({ data }) => {
        runInAction(() => (this._departments = [data, ...this._departments]));
        toast.update(id, {
          render: "All is good",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      })
      .catch((e) => {
        console.log("adding department error: ", e);

        this.onError(e);

        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
      });
  };

  updateCar = async (car) => {
    const id = toast.loading("Updating car...");
    const index = this._cars.findIndex((c) => c.id === car.id);
    this._cars[index] = car;
    const curIndex = this._currentCars.findIndex((c) => c.id === car.id);
    this._currentCars[curIndex] = car;

    await this.http
      .put(`${process.env.REACT_APP_CARS}${car.id}/`, car)
      .then(({ data }) => {
        toast.update(id, {
          render: "Car updated",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
        console.log("edited car: ", data);
      })
      .catch((e) => {
        toast.update(id, {
          render: "Car update failed",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        console.log("Error editing car: ", e);
        this.onError(e);
      });
  };

  addCar = async (car) => {
    const id = toast.loading("Adding car...");
    await this.http
      .post(`${process.env.REACT_APP_CARS}`, car, {
        "Content-Type": "application/json",
      })
      .then(({ data }) => {
        this._cars.unshift(data);
        this._currentCars.push(data);
        toast.update(id, {
          render: "Car successfully added",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      })
      .catch((e) => {
        console.log("Error adding new car: ", e);
        this.onError(e);
        toast.update(id, {
          render: "Car adding failed",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
      });
  };

  setCurrentCars = (cars) => {
    this._currentCars = cars;
  };

  setCurrentTrips = (trips) => {
    this._currentTrips = trips;
  };

  resetState = () => {
    this._cars = [];
    this._trips = [];
    this._departments = [];
    this._models = [];
    this._company = [];
    this._showTrips = [];
    this._showCars = [];
    this._showHistory = [];
    this._searchHistory = [];

    this._loading = true;

    this._currentTrips = [];
    this._currentCars = [];

    this._24hrsIntervalID = null;

    this.http = new Http();
    window.history.go();
  };

  loadData = async () => {
    if (this.userStore.token == null) return;

    const id = toast.loading("Loading data...");

    await this.loadCars();
    await this.loadDepartments();
    await this.loadModels();
    await this.loadCompanies();
    await this.loadTrips();

    if (this._24hrsIntervalID === null) {
      const now = new Date(Date.now());
      let startTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      );
      startTime = getDateTimeString(new Date(startTime));
      this.createDailyMovement(startTime);
    }
    toast.update(id, {
      render: "All is loaded",
      type: "success",
      isLoading: false,
      ...toastOptions,
    });
  };

  loadCars = async () => {
    //  load cars
    await this.http
      .get(`${process.env.REACT_APP_CARS}`)
      .then(({ data }) => {
        this._cars = [...data.results];
        this._currentCars = this._cars;
        this._showCars = this._cars.map((car) => car.id);
      })
      .catch((e) => {
        console.log("Error loading cars: ", e);
        this.onError(e);
      });
  };

  loadCompanies = async () => {
    //  load company
    await this.http
      .get(`${process.env.REACT_APP_COMPANY}`)
      .then(({ data }) => {
        this._company = [...data.results];
      })
      .catch((e) => {
        console.log("Error loading companies: ", e);
        this.onError(e);
      });
  };

  loadDepartments = async () => {
    //  load departments
    await this.http
      .get(`${process.env.REACT_APP_DEPARTMENTS}`)
      .then(({ data }) => {
        this._departments = [
          ...data.results.map((el) => ({ ...el, show: true })),
        ];
      })
      .catch((e) => {
        console.log("Error loading departments: ", e);
        this.onError(e);
      });
  };

  loadTrips = async () => {
    this._showTrips = [];
    await this.http
      .get(`${process.env.REACT_APP_TRIPS}`)
      .then(({ data }) => {
        console.log("loaded trips: ", data);
        this._trips = [...data.results];
        this._currentTrips = [...data.results];
      })
      .catch((e) => {
        console.log("error loading trips: ", e);
        this.onError(e);
      });
  };

  loadModels = async () => {
    //  load models
    await this.http
      .get(`${process.env.REACT_APP_MODELS}`)
      .then(({ data }) => {
        this._models = [...data.results];
      })
      .catch((e) => {
        console.log("Error loading models: ", e);
        this.onError(e);
      });
  };

  setLoading = (value) => {
    this._loading = value;
  };

  setLoadingLocales = (value) => {
    this._loadingLocales = value;
  };

  completeDailyRouteOnRedirect = () => {
    if (this._24hrsIntervalID === null) return;

    const cars = this._cars.map((car) => ({
      id: car.id,
      dateTime: car.last_position.timestamp,
    }));

    const promises = cars.map((car) => {
      return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_CARS_TRACKING}?car_id=${car.id}&start_time=${car.dateTime}&fields=id,longitude,latitude,is_parked,speed,car,timestamp`;
        await this.http
          .get(url)
          .then(({ data }) => {
            const daily = this._searchHistory.find(
              (sh) => sh.id == this._24hrsIntervalID
            );

            const carMovement = daily.records.find(
              (carMovement) => carMovement.car === car.id
            );

            carMovement.records = [...carMovement.records, ...data.results];
            resolve(data.results);
          })
          .catch((e) => {
            console.log("error completeDailyRouteOnRedirect: ", e);
            this.onError(e);
            reject(e);
          });
      });
    });

    Promise.all(promises)
      .then((values) => {
        //  update car coords
        runInAction(() => {
          values
            .map((car) => car !== undefined && car[car.length - 1])
            .forEach((rec) => {
              if (rec === null || rec === undefined) return;
              this._cars.find((car) => car.id == rec.car).last_position = rec;
            });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  continueDailyRoute = (item, newData) => {
    item.records = item.records.map((carMovement) => ({
      ...carMovement,
      records: [
        ...carMovement.records,
        ...newData
          .filter((nd) => nd.id == carMovement.car)
          .map((nd) => nd.last_position),
      ],
    }));
  };

  createDailyMovement = async (startTime) => {
    const carIDs = this._cars.map((car) => car.id);
    const toastID = toast.loading("Loading cars daily movement...");

    let endTime = new Date(Date.now());
    endTime = getDateTimeString(endTime);

    const searchData = {
      id: Date.now(),
      ids: carIDs,
      records: [],
      startTime,
      endTime,
      userId: this.userStore.userId,
    };

    const promises = carIDs.map((carID, i) => {
      return new Promise(async (resolve, reject) => {
        const query = `car_id=${carID}&start_time=${startTime}`;
        const url = `${process.env.REACT_APP_CARS_TRACKING}?${query}&fields=id,longitude,latitude,is_parked,speed,car,timestamp`;
        await this.http
          .get(url)
          .then(({ data }) => {
            const carFlow = { car: carID, records: data.results };

            searchData.records.push(carFlow);

            if (this._24hrsIntervalID !== null) {
              const daily = this._searchHistory.find(
                (sh) => sh.id == this._24hrsIntervalID
              );
              daily.records.push(carFlow);
            }

            if (i == 0) {
              this.addToShowHistory(searchData.id);
              this.addToSearchHistory(searchData);
              this._24hrsIntervalID = searchData.id;
            }
            toast.update(toastID, {
              render: `Loading ${i + 1} / ${carIDs.length} cars`,
            });
            const carNumber = this._cars.find((car) => car.id == carID).number;
            toast.success(`Done loading ${carNumber}`, {
              ...toastOptions,
              autoClose: 500,
            });
            resolve(data.results);
          })
          .catch((e) => {
            reject(e);
            this.onError(e);
          });
      });
    });

    Promise.all(promises)
      .then((values) => {
        //  check cached movements
        const cached_movement = JSON.parse(
          localStorage.getItem("cached_movement")
        );
        if (cached_movement !== null) {
          const daily = this._searchHistory.find(
            (sh) => sh.id == this._24hrsIntervalID
          );
          daily.records = daily.records.map((carMovement) => {
            return {
              ...carMovement,
              records: [
                ...carMovement.records,
                ...cached_movement.filter((cm) => cm.car == carMovement.car),
              ],
            };
          });
          localStorage.removeItem("cached_movement");
        }

        toast.update(toastID, {
          render: `Done loading cars movement`,
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      })
      .catch((e) => console.log("Something went wrong: ", e));
  };

  createSearchHistory = async (startTime, endTime, car_id) => {
    const id = toast.loading("Creating interval...");
    //  finished query string
    const query = `car_id=${car_id}&start_time=${startTime}&end_time=${endTime}`;
    const url = `${process.env.REACT_APP_CARS_TRACKING}?${query}&fields=id,longitude,latitude,is_parked,speed,car,timestamp`;
    await this.http
      .get(url)
      .then(({ data }) => {
        if (this.userStore.userId === null) return;

        console.log("got search records: ", data);

        const searchData = {
          id: Date.now(),
          ids: [car_id],
          records: [{ car: car_id, records: data.results }],
          startTime,
          endTime,
          userId: this.userStore.userId,
        };

        this.addToShowHistory(searchData.id);
        this.addToSearchHistory(searchData);

        toast.update(id, {
          render: "Interval successfully created",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      })
      .catch((e) => {
        console.log("error making interval", e);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        this.onError(e);
      });
  };

  updateCarsPos = async () => {
    if (this._loading || this.userStore.token === null) return;
    const url = `${process.env.REACT_APP_CARS}?fields=id,last_position`;
    await this.http
      .get(url)
      .then(({ data }) => {
        this.updateCars(data.results);
      })
      .catch((e) => {
        console.log("Error updating cars pos: ", e);
        this.onError(e);
      });
  };

  setShowCars = (cars) => {
    if (this._showCars.length !== 0) this._lastShowCars = this._showCars;

    this._showCars = cars;
    this.updateCarsPos();
  };

  loadTripRecords = async (tripID) => {
    const trip = this._trips.find((t) => t.id == tripID);

    if (trip === undefined || trip?.records) return;

    const toastID = toast.loading("Loading trip movement...");

    let url = trip?.json_file;

    if (!trip?.json_file) {
      const query = `car_id=${trip.car}&start_time=${trip.start_time}&end_time=${trip.finish_time}`;
      url = `${process.env.REACT_APP_CARS_TRACKING}?${query}`;
    }

    await this.http
      .get(url)
      .then(({ data }) => {
        let newData = data;
        runInAction(() => {
          if (!trip?.json_file) newData = data.results;
          trip.records = newData;
        });
        toast.update(toastID, {
          render: "Successfully loaded trip",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
        console.log("loaded records for trip info page: ", newData);
      })
      .catch((e) => {
        console.log("error loading trips records: ", e);
        this.onError(e);
        toast.update(toastID, {
          render: "Trip doesn`t loaded",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
      });
  };

  get cars() {
    return this._cars;
  }

  get departments() {
    return this._departments;
  }

  get showTrips() {
    return this._trips.filter((trip) => this._showTrips.includes(trip.id));
  }

  get showCars() {
    return this._cars.filter((car) => this._showCars.includes(car.id));
  }

  get trips() {
    return this._trips;
  }

  get searchHistory() {
    return this._searchHistory;
  }

  get showHistory() {
    return this._searchHistory.filter((item) =>
      this._showHistory.includes(item.id)
    );
  }

  get currentCars() {
    return this._currentCars;
  }

  get currentTrips() {
    return this._currentTrips;
  }

  get models() {
    return this._models;
  }

  get company() {
    return this._company;
  }

  get loading() {
    return this._loading;
  }

  get loadingLocales() {
    return this._loadingLocales;
  }

  getEvents = async (options) => {
    const { carNumber, startDate, endDate } = options;
    const id = toast.loading("Loading events...");
    const idsQuery = `car_id=${carNumber}`;
    //  finished query string
    const query =
      endDate === null
        ? `${idsQuery}&start_time=${startDate}`
        : `${idsQuery}&start_time=${startDate}&end_time=${endDate}`;
    const url = `${process.env.REACT_APP_CARS_TRACKING}?${query}&fields=id,longitude,latitude,is_parked,speed,car,event,timestamp&events=1`;
    return await this.http
      .get(url)
      .then(({ data }) => {
        console.log(data);
        toast.update(id, {
          render: "Events loaded",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
        return data;
      })
      .catch((e) => {
        console.log("error making interval", e);
        this.onError(e);
        toast.update(id, {
          render: "Events load failed",
          type: "error",
          isLoading: false,
          ...toastOptions,
        });
        return null;
      });
  };

  getCarTuple = () => {
    return this._cars.map((car) => ({ id: car.id, name: car.number }));
  };

  getCarNumberByID = (carID) => {
    return this._cars.find((car) => car.id == carID)?.number;
  };

  getCarTrackingColor = (carID) => {
    return this._cars.find((car) => car.id == carID)?.track_color;
  };

  getDailyID = () => {
    return this._24hrsIntervalID;
  };

  onError = (e) => {
    const { error, status } = e;
    if (status == 401) {
      this.resetState();
      this.userStore.resetUserData();
    }
  };
}

export default new AppStore();
