import React from "react";
import { observer } from "mobx-react";

import { MapWrapper } from "../Wrappers/MapWrapper";
import { SideMenu } from "../SideMenu";
import { Modals } from "../Wrappers/Modals";
import { StoreContext } from "../../store/StoreContext";
import { LoadingCircle } from "../UI/LoadingCircle";

export const Main = observer(() => {
  const { loading, completeDailyRouteOnRedirect } =
    React.useContext(StoreContext);

  const [tab, setTab] = React.useState("cars");

  React.useEffect(() => {
    //updateCarsPos();
    completeDailyRouteOnRedirect();
  }, []);

  if (loading) return <LoadingCircle />;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SideMenu changeTab={(newTab) => setTab(newTab)} />
      <MapWrapper tab={tab} />
      <Modals />
    </div>
  );
});
