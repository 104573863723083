import React from "react";
import { observer } from "mobx-react";

import { StoreContext } from "../../store/StoreContext";
import { LineComponent } from "./LineComponent";

export const TripsRender = observer(() => {
  const { showTrips, getCarTrackingColor } = React.useContext(StoreContext);

  if (showTrips.length === 0) return null;

  return (
    <>
      {showTrips?.map((trip) => (
        <LineComponent
          key={trip.id.toString()}
          records={trip.records}
          lineColor={getCarTrackingColor(trip.car)}
        />
      ))}
    </>
  );
});
