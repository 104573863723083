import React from "react";
import { makeStyles } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { IntervalsPickerWithAuto } from "../Forms/IntervalsPickerWithAuto";
import { StoreContext } from "../../store/StoreContext";
import { BreadcrumbsCreator } from "../Creators/BreadcumbsCreator";
import { TableCreator } from "../Creators/TableCreator";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30,
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "#aaa",
    transition: "0.2s",
    "&:hover": {
      color: "#000",
    },
  },
});

export const Events = () => {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const { getEvents, getCarNumberByID } = React.useContext(StoreContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    document.title = "ACMon " + t("EVENTS_PAGE");
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const {
      start_time: { value: startDate },
      end_time: { value: endDate },
      car: { value: carNumber },
    } = event.target.elements;

    const options = { carNumber, startDate, endDate };
    getEvents(options)
      .then(({ results }) => {
        setItems(results);
      })
      .catch((e) => console.log(e));
  };

  const tableKeys = [
    "car",
    "speed",
    "latitude",
    "longitude",
    "timestamp",
    "event",
  ];

  const tableExcludes = [
    {
      key: "event",
      getContent: (event) => {
        return `${event.code}: ${event.name} ${
          event.units ? !!event.value : event.value
        } ${event?.units ?? ""}`;
      },
    },
    {
      key: "car",
      getContent: (value) => {
        return getCarNumberByID(value);
      },
    },
    {
      key: "timestamp",
      getContent: (value) => {
        return value.replace("T", " ");
      },
    },
    {
      key: "speed",
      getContent: (value) => {
        return `${value} ${t("KM_H")}`;
      },
    },
  ];

  const breadcrumbsLinks = [
    { color: "inherit", path: "/", class: classes.link, title: t("MAP") },
  ];
  const breadcrumbsCurrent = { color: "textPrimary", text: t("EVENTS") };

  return (
    <>
      <div className={classes.container}>
        <BreadcrumbsCreator
          separator={<NavigateNext fontSize="small" />}
          links={breadcrumbsLinks}
          current={breadcrumbsCurrent}
        />
        <IntervalsPickerWithAuto
          align="horizontal"
          translator={t}
          onSubmit={onSubmit}
        />
        <div style={{ margin: 15 }}></div>
        <div>
          <TableCreator
            items={items}
            parametrs={tableKeys}
            excludes={tableExcludes}
          />
        </div>
      </div>
    </>
  );
};
